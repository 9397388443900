<template>
    <div class="settings-container">
        <OtpModal :twoStep="twoStep" v-if="showModal" @close="showModal=false" />
        <div class="change-password">
            <h2> شناسه دو عاملی </h2>
            <div class="chose-option">
                <p class="explain"> جهت ورود به حساب کاربری و درخواست برداشت و تغییر رمز عبور یکی از راه های زیر را برای شناسه دو عاملی انتخاب کنید </p>
                <div class="option-container">
                    <div @click="otpChange('EMAIL')" :class="{'selected':state.userInfo.towStepAuthenticationType=='EMAIL'}" class="item">
                        <div :class="{'checked':state.userInfo.towStepAuthenticationType=='EMAIL'}" class="checkbox"></div>
                        <span> ایمیل </span>
                    </div>
                    <div @click="otpChange('GOOGLE_AUTHENTICATOR')" :class="{'selected':state.userInfo.towStepAuthenticationType=='GOOGLEAUTHENTICATOR'}" class="item">
                        <div :class="{'checked':state.userInfo.towStepAuthenticationType=='GOOGLEAUTHENTICATOR'}" class="checkbox"></div>
                        <span> گوگل </span>
                    </div>
                    <div @click="otpChange('SMS')" :class="{'selected':state.userInfo.towStepAuthenticationType=='SMS'}" class="item">
                        <div :class="{'checked':state.userInfo.towStepAuthenticationType=='SMS'}" class="checkbox"></div>
                        <span> پیامک </span>
                    </div>
                </div>
            </div>
            <button style="cursor: default;background:none" @click.prevent="" disabled class="submit"> {{'شناسه دو عاملی فعال : '+ ' '+this.$athenType[state.userInfo.towStepAuthenticationType]}} </button>
        </div>
        <form @submit.prevent="submit" class="change-password">
            <h2> تغییر رمز عبور </h2>
            <div class="inputs-container">

                <div class="label-container">
                    <div :class="oldPassWarn? 'div-error' : (fOldPass? 'div-active':'')" class="input">
                        <label for="input">
                            <span :class="oldPassWarn? 'span-error' : (fOldPass || oldPassword? 'span-active':'')"> رمز عبور فعلی </span>
                            <input @focus="fOldPass=true" v-model="oldPassword" :type="showOldPass ? 'text':'password'">
                        </label>
                        <inline-svg @click="showOldPass=true" v-if="!showOldPass" :class="oldPassWarn? 'svg-error' : (fOldPass? 'svg-active':'')" class="svg pass-svg" :src="require('../../../assets/Icons/seePass.svg')" />
                        <inline-svg @click="showOldPass=false" v-if="showOldPass" :class="oldPassWarn? 'svg-error' : (fOldPass? 'svg-active':'')" class="svg pass-svg" :src="require('../../../assets/Icons/dontSeePass.svg')" />
                    </div>
                    <span v-if="oldPassWarn" class="warn"> {{oldPassWarn}} </span>
                </div>



                <div class="label-container">
                    <div :class="newPassWarn? 'div-error' : (fNewPass? 'div-active':'')" class="input">
                        <label for="input">
                            <span :class="newPassWarn? 'span-error' : (fNewPass || NewPassword? 'span-active':'')"> رمز عبور جدید </span>
                            <input @focus="fNewPass=true" v-model="NewPassword" :type="showNewPass ? 'text':'password'">
                        </label>
                        <inline-svg @click="showNewPass=true" v-if="!showNewPass" :class="newPassWarn? 'svg-error' : (fNewPass? 'svg-active':'')" class="svg pass-svg" :src="require('../../../assets/Icons/seePass.svg')" />
                        <inline-svg @click="showNewPass=false" v-if="showNewPass" :class="newPassWarn? 'svg-error' : (fNewPass? 'svg-active':'')" class="svg pass-svg" :src="require('../../../assets/Icons/dontSeePass.svg')" />
                    </div>
                    <span v-if="newPassWarn" class="warn"> {{newPassWarn}} </span>
                </div>



                <div class="label-container">
                    <div :class="repeatPassWarn? 'div-error' : (fRepeatPass? 'div-active':'')" class="input">
                        <label for="input">
                            <span :class="repeatPassWarn? 'span-error' : (fRepeatPass || repeatPassword? 'span-active':'')"> تکرار رمز عبور </span>
                            <input @focus="fRepeatPass=true" v-model="repeatPassword" :type="showRepeatPass ? 'text':'password'">
                        </label>
                        <inline-svg @click="showRepeatPass=true" v-if="!showRepeatPass" :class="repeatPassWarn? 'svg-error' : (fRepeatPass? 'svg-active':'')" class="svg pass-svg" :src="require('../../../assets/Icons/seePass.svg')" />
                        <inline-svg @click="showRepeatPass=false" v-if="showRepeatPass" :class="repeatPassWarn? 'svg-error' : (fRepeatPass? 'svg-active':'')" class="svg pass-svg" :src="require('../../../assets/Icons/dontSeePass.svg')" />
                    </div>
                    <span v-if="repeatPassWarn" class="warn"> {{repeatPassWarn}} </span>
                </div>


            </div>
            <button :disabled="disable" class="submit"> ثبت </button>
        </form>
    </div>
</template>

<script>
import OtpModal from './OtpModal.vue'
    export default {
  components: { OtpModal },
        name:'Settings',
        data() {
            return {
                showModal:false,

                showOldPass:false,
                fOldPass:false,
                oldPassword:'',


                showNewPass:false,
                fNewPass:false,
                NewPassword:'',


                showRepeatPass:false,
                fRepeatPass:false,
                repeatPassword:'',

            }
        },
        methods: {
            otpChange(e){
                this.twoStep=e;
                this.showModal=true;
            },
            async submit() {
                
                let passSent = {
                    oldPassword: this.oldPassword,
                    password: this.NewPassword,
                }
                if (this.repeatPassword == this.NewPassword){
                    this.state.loading = true
                    try {
                        const res = await this.$axios.post(`/users/change-password`, passSent)
                        if (res.message === "password changed successfully") {
                            this.$error('رمز شما تغییر یافت','','success')
                            this.oldPassword = ''
                            this.NewPassword = ''
                            this.repeatPassword = ''
                        }
                    } catch (error) {
                        if (error.response.data.message === 'Old Password Is Incorrect') {
                            this.$error('رمز قبلی اشتباه است.' ,'') 
                        }
                    }
                }
            },
        },
        computed:{
            disable(){
                return this.oldPassWarn || this.newPassWarn || this.repeatPassWarn || !this.oldPassword || !this.NewPassword || !this.repeatPassword
            },
            oldPassWarn(){
                let err
                if(this.fOldPass){
                if(!this.oldPassword){
                    err='رمز عبور فعلی را وارد کنید'
                }else{
                    err = false
                }
                }else{
                    err = false
                }
                return err
            },
            newPassWarn(){
                let err
                if(this.fNewPass){
                if(!this.NewPassword){
                    err='رمز عبور جدید را وارد کنید'
                }else if(!this.$validPass(this.NewPassword) || this.NewPassword.length < 8){
                    err='رمز عبور باید حداقل ۸رقم و شامل یک حرف کوچک و یک حرف بزرگ باشد'
                } else{
                    err = false
                }
                }else{
                    err = false
                }
                return err
            },
            repeatPassWarn(){
                let err
                if(this.fRepeatPass){
                if(!this.repeatPassword){
                    err='تکرار رمز عبور را وارد کنید'
                }else{
                    err = false
                }
                }else{
                    err = false
                }
                return err
            },
        },
        created:function(){
            window.addEventListener("click",event => {
                if(!event.target.closest('.label-container')){
                    this.fOldPass=false
                    this.fNewPass=false
                    this.fRepeatPass=false
                }
            })
        }
    }
</script>

<style lang="scss" scoped>
.chose-option{
    display: flex;
    flex-direction: column;
    row-gap: 32px;
    width: 100%;
    .explain{
        color: var(--black);
        font-weight: 500;
        font-size: .9em;
    }
    .option-container{
        display: flex;
        flex-direction: column;
        row-gap: 24px;
        .item{
            cursor: pointer;
            display: flex;
            flex-direction: row;
            column-gap: 8px;
            color: var(--a-hover);
            font-weight: 500;
            font-size: 1em;
            align-items: center;
        }
        .checkbox{
            width: 12px;
            height: 12px;
            border-radius: 50%;
            border: 1px solid #D7C8C8;
            background: transparent;
        }
        .checked{
            border: none !important;
            background: var(--green) !important;
        }
        .selected{
            color: var(--black) !important;
        }
    }
}
.inputs-container{
    display: flex;
    flex-direction: column;
    row-gap: 28px;
    width: 100%;
}
.change-password{
    flex-grow: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 36px;
    padding: 32px 24px;
    background: #F0F0F5;
    box-shadow: 0px 0px 8px -1px rgba(65, 70, 192, 0.2);
    border-radius: 4px;
    max-width: 460px;
    h2{
        color: var(--darker-primary);
        font-weight: 700;
        font-size: 1.4em;
        text-align: center;
    }
}
.settings-container{
    // padding-top: 180px;
    width: 100%;
    height: 100%;
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
    flex-wrap: wrap;
}
</style>