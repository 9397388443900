<template>
    <transition name="fade">
        <div @click.self="closeModale" class="modal-backdrop" >
        <transition name="fade">
            <p v-if="alertLink" class="alert"> کپی شد </p>
        </transition>
          <div class="modal">
              <h2> تایید کد </h2>
                <div class="inputs-container">


                    <span v-if="twoStep=='EMAIL'" class="title">
                    کد ارسالی به ایمیل خود را وارد کنید
                    </span>
                    <span v-if="twoStep=='SMS'" class="title">
                    کد ارسالی به شماره موبایل خود را وارد کنید
                    </span>
                    <span v-if="twoStep=='GOOGLE_AUTHENTICATOR'" class="title">
                    برنامه google authenticator را نصب کنید و سپس با اسکن کد زیر می توانید در این برنامه به شناسه دوعاملی کندل دسترسی داشته باشید
                    </span>


                    <div v-if="twoStep == 'GOOGLE_AUTHENTICATOR'" class="google-qr">
                        <img 
                        width="180px"
                        :src="'data:image/png;base64,'+qrCode"
                        alt="">
                        <div class="links">
                            <p @click="Copy(qrLink.match(/secret=(.*?)&/)[1])"> {{qrLink.match(/secret=(.*?)&/)[1]}} </p>
                            <img style="max-width:24px" src="../../../assets/Icons/clipboard.svg" alt="">
                        </div>
                    </div>




                    <div v-if="twoStep !== 'GOOGLE_AUTHENTICATOR'" class="show-email">
                        <inline-svg :class="emailWarn? 'svg-error' : (femail? 'svg-active':'')" class="svg" :src="require('../../../assets/Icons/Email.svg')" />
                        <span class="email">
                            {{twoStep=='EMAIL' ? state.userInfo.email : state.userInfo.mobileNumber}}
                        </span>
                    </div>




                <CodeInput 
                            :loading="false" 
                            class="verif-code" 
                            v-on:change="onChange" 
                            @completed="submit"
                            
                    />

                </div>


                <div class="btns">
                    <button @click.prevent="submit" :disabled="otp.code.length !== 6" class="submit"> ثبت </button>
                </div>
          </div>
        </div>
    </transition>
</template>

<script>
import CodeInput from "vue-verification-code-input";
export default {
    props:['twoStep'],
  name: 'OtpSetting',
  components:{
            CodeInput,

        },
  data() {
      return {
          alertLink:false,
          otp:{
            code: '',
        },
        countDown: 90,
        qrCode:'',
        qrLink:'',
          
      }
  },
  methods : {
      closeModale() {
        this.$emit('close' , false)
      },
    Copy(text){
      navigator.clipboard.writeText(text);
      this.alertLink=true
      setTimeout(() => {
        this.alertLink=false
      }, 1500);
    },
      countDownTimer() {
                if (this.countDown > 0) {
                    this.timeOut = setTimeout(() => {
                        this.countDown--
                        this.countDownTimer()
                    }, 1000)
                } else {
                    this.$error('زمان شما به اتمام رسید', '')
                }
            },
      async send() {
                this.state.loading = true
                const a = {
                    EMAIL: 'ایمیل',
                    SMS: 'شماره',
                    'GOOGLE_AUTHENTICATOR':'اپلیکیشن گوگل'
                }
                const res = await this.$axios.post(`/users/send-verification-code?twoStepAuthorization=${this.twoStep}`)
                if(this.twoStep == 'GOOGLE_AUTHENTICATOR'){
                    this.qrCode = res.baseDTO.file || ''
                    this.qrLink = res.baseDTO.qrCodeLink || ''
                }
                if (res.message === 'Verification Code Sent') {
                    this.$error('', 'کد به ' + a[this.twoStep] + ' شما ارسال شد', 'success')
                    if (this.countDown === 0)
                        this.countDown = 90
                    this.countDownTimer()
                }
      },
      async submit() {
                const a = {
                    'EMAIL': 'ایمیل',
                    'SMS': 'پیامک',
                    'GOOGLE_AUTHENTICATOR': 'Google Authenticator'
                }
                if (this.otp.code.toString().length === 6 && (this.twoStep ==='GOOGLE_AUTHENTICATOR' || this.countDown !== 0)) {
                    if (this.$route.name !== 'Wallet') {
                        this.state.loading = true
                        const [res,] = await this.$http.post(`/users/change-otp?twoStepAuthorization=${this.twoStep}&code=${this.otp.code}`)
                        if(res){
                            this.$emit('closeChargeModaleEmit')
                            this.$error('submit', 'شناسایی دو مرحله ای شما به ' + a[this.twoStep] + ' تغییر یافت.','success')
                            this.closeModale();
                        }
                    } else {
                        this.$emit('submit', this.otp.code)
                    }
                }
        },
      onChange(v){
          this.otp.code=v
      }
  },
  mounted() {
      this.send();
  },
  }
</script>

<style lang="scss" scoped>
.alert{
    position: absolute;
    left: 48%;
    bottom: 100px;
    box-sizing: border-box;
    padding: 8px;
    box-shadow: 0px 0px 16px rgba(0, 119, 204, .2);
    color: rgba(64, 64, 64, .7);
    border-radius: 4px;
    z-index: 5;
    width: max-content;
}


.google-qr{
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 16px;
    .links{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }
}
.btns{
    width: 100%;
    .submit{
        width: 100%;
    }
}
.inputs-container{
    display: flex;
    flex-direction: column;
    row-gap: 23px;
    width: 100%;
    padding-bottom: 18px;
    .title{
        font-weight: 500;
        font-size: clamp(14px,2vw,16px);
        color: var(--black);
    }
    .show-email{
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 6px;
        .email{
            color: var(--black);
            font-weight: 400;
            font-size: clamp(10px,1vw,12px);
        }
    }
}
.modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 119, 204, .1);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 110;
  }
  .modal {
    display: flex;
    flex-grow: 1;
    max-width: 450px;
    row-gap: 12px;
    // min-height: 160px;
    background: #F0F0F5;
    box-shadow: 0px 0px 8px -1px rgba(65, 70, 192, 0.2);
    border-radius: 8px;
    position: relative;
    margin: 24px;
    box-sizing: border-box;
    padding: 24px;
    padding-bottom:24px ;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
</style>